/** 这里打包业务样式 **/
import '@styles/styles.scss'  // 引入全局样式
import './index.scss'   //pc样式
import './middle.scss'  // ipad样式
import './small.scss'   // iphone样式

/** 这里编写业务逻辑 **/

// 手机版的导航开启和关闭
document.getElementById("menu-open").onclick=function(){
    let mheader = document.getElementById('m-header-cover')
    mheader.classList.add('active')
}
document.getElementById("menu-close").onclick=function(){
    let mheader = document.getElementById('m-header-cover')
    mheader.classList.remove('active')
}

// pc版导航根据页面滚动显示隐藏效果
import Headroom from "headroom.js";
let header_dom = document.querySelector("header .header-wrap .pc-header");
let headroom  = new Headroom(header_dom);
headroom.init();
