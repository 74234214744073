/** 这里打包业务样式 **/
import '@styles/styles.scss'  // 引入全局样式
import './index.scss'   //pc样式
import './middle.scss'  // ipad样式
import './small.scss'   // iphone样式

/** 这里编写业务逻辑 **/
import 'animate.css/animate.css'

const element = document.querySelector('#banner-fuwu h2');
element.classList.add('animate__animated', 'animate__bounce');
