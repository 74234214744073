class SwiperPlugin {
    /**
     * 基于swiper的扩展插件，实现一些额外功能
     * @param ele
     * @param obj
     */

    lock(ele, obj) {
        /**
         * 鼠标移入锁定，移出继续轮播
         * ele：swiper-container所在的dom
         * obj：已经实例化的swiper对象
         */
        // 鼠标移入
        ele.mouseenter(function () {
            obj.autoplay.stop();
        })

        // 鼠标移出
        ele.mouseleave(function () {
            obj.autoplay.start();
        })
    }
}

export default SwiperPlugin
