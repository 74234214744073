/** 这里打包业务样式 **/
import '@styles/styles.scss'  // 引入全局样式
import './index.scss'   //pc样式
import './middle.scss'  // ipad样式
import './small.scss'   // iphone样式

/** 这里编写业务逻辑 **/

import Swiper from "swiper";
import SwiperPlugin from "@utils/swiper-plugin";
import $ from "jquery";

// 顶部banner轮播控制
let bannerSwiper = new Swiper('#banner .swiper-container', {
        loop: true,
        speed:1000,
        autoHeight: false, //高度随内容变化
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    })


// swiper鼠标移入移出控制暂停和继续
let sw = new SwiperPlugin();
sw.lock($('#banner .swiper-container'), bannerSwiper);


